var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:_vm.$style.page},[_c('div',{class:_vm.$style.wrapper},[_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.$router.push(_vm.$options.ADDEVENT_ADMIN_ROUTES.CATALOG.CATEGORIES.CREATE)}}},[_vm._v(" Создать категорию ")])],1),_c('el-table',{attrs:{"data":_vm.categories,"stripe":""},on:{"sort-change":_vm.onSortChange}},[_c('el-table-column',{attrs:{"prop":"priority","label":"Приоритет","sortable":""}}),_c('el-table-column',{attrs:{"prop":"name","label":"Название категории","sortable":""}}),_c('el-table-column',{attrs:{"label":"Фото"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('div',{class:_vm.$style.image},[_c('img',{attrs:{"src":_vm.$configData.s3_link + scope?.row?.image?.original,"alt":scope?.row?.image?.caption},on:{"error":_vm.setDefaultImage}})])]}}])}),_c('el-table-column',{attrs:{"width":"75"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('router-link',{class:_vm.$style.actions,attrs:{"to":_vm.getRoute({
              route:
                _vm.$options.ADDEVENT_ADMIN_ROUTES.CATALOG.CATEGORIES.CATALOG,
              params: { id: scope?.row?.id },
            })}},[_vm._v(" Каталог ")])]}}])}),_c('el-table-column',{attrs:{"width":"130"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('ActionButtons',{attrs:{"name":"trash","edit-link":_vm.getRoute({
              route: _vm.$options.ADDEVENT_ADMIN_ROUTES.CATALOG.CATEGORIES.UPDATE,
              params: { id: scope?.row?.id },
            }),"view-link":_vm.getRoute({
              route: _vm.$options.ADDEVENT_ROUTES.CATALOG.CATEGORY,
              params: { slug: scope?.row?.slug },
              site: _vm.$options.PROJECTS.ADDEVENTS,
            })},on:{"delete":function($event){return _vm.deleteCategory(scope?.row)}}})]}}])})],1),(_vm.totalPages > 1)?_c('el-pagination',{class:_vm.$style.pagination,attrs:{"background":"","layout":"prev, pager, next","page-size":_vm.query.limit,"total":_vm.count,"current-page":_vm.query.page},on:{"update:currentPage":function($event){return _vm.$set(_vm.query, "page", $event)},"update:current-page":function($event){return _vm.$set(_vm.query, "page", $event)},"current-change":_vm.getCategories}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }